<template>
  <section>
    <div class="main">
      <h3>Privacy Policy</h3>
      <p><strong>We don’t collect or share any personal data.</strong></p>
      <p>This is what SecurTeam has been built for.</p>
      <p>
        At SecurTeam, privacy is built into the core of our product and
        technology. We have a strict no logs and no history policy and do not
        collect any user data or communication content. All your messages,
        files, audio and video calls are encrypted using keys that only you have
        access to, and which we cannot replicate. In other words, we do not and
        technically cannot view your data even if we wanted to.
      </p>
      <p>
        When you enter a voice or video call with another user of SecurTeam, or
        through a SecurMeet conference link, a Peer-to-Peer connection is
        established to handle the communication. This means that the audio and
        video data in that call are transferred directly from device to device
        without going through any middleman server, even ours. This means that
        it is technically impossible for us, or even for your own IT staff, to
        access the content of these communications. If there are too many people
        on a conference call for your device to handle it, the signal is
        automatically routed via your dedicated instance or local server,
        depending on your configuration. Either way, the signal is encrypted
        end-to-end to prevent any interception or snooping.
      </p>
      <p>
        We do not log, track or otherwise exploit any metadata or information
        about your communications. This means that we do not know or record how
        often you use the app, how many messages you send or to whom you send
        them.
      </p>
      <p>
        <strong>Account and Profile Information</strong>. The only identifiable
        information we collect about you is the username and email address you
        provide during sign up, as well as some information needed for
        two-factor authentication (like your phone number) should you decide to
        use this optional feature. This information is used to create and
        maintain your account and subscription but will never be shared with
        third parties.
      </p>
      <p>
        <strong>Analytics</strong>. We use Google Analytics on the SecurTeam
        website as an analytics provider (but never in the SecurTeam app or web
        app). We use it to measure our main site’s performance and visitors but
        are unable to match this data with any user data to identify you
        individually. To learn more about the privacy policy of Google
        Analytics, refer to Google’s Policies and Principles. Use the Google
        Analytics Opt-Out browser add-on to prevent to analytics information
        from being sent to Google Analytics.
      </p>
      <p>
        Note that there are no analytics plugins on the logged-in section of the
        website.
      </p>
      <p>
        <strong>Cookies and Other Tracking Technologies</strong>. We use cookies
        to improve and customize SecurTeam Services and your experience; it
        allows you to access and use websites or SaaS Products without
        re-entering your username or password every time. You can however
        instruct your browser to stop accepting cookies or to prompt you before
        accepting a cookie from websites you visit. Your browser’s Options menu
        will have your cookie settings. If you do not accept cookies, however,
        you may not be able to use all aspects of our website and may need to
        log in several times during one session.
      </p>
      <p>
        If you decide to participate in a survey, contest, promotion, event, or
        if you request customer support, or communicate via third party social
        media sites or via our main website, some of your personal data may be
        collected or may be visible publicly, but we will never share this data
        with third parties.
      </p>
      <p>
        <strong>General Uses</strong>. We may use whatever little personal data
        you communicate to us (username, email and optionally a phone number)
        for a variety of purposes, including to:
      </p>
      <p>
        – Provide, operate, maintain, improve, and promote SecurTeam
        Services;<br />
        – Enable you to access and use SecurTeam Services;<br />
        – Process and complete transactions, and send you related information,
        including purchase confirmations and invoices;<br />
        – Communicate with you, including responding to your comments,
        questions, and requests; providing customer service and support;
        providing you with information about services, features, surveys,
        newsletters, offers, promotions, contests and events; providing other
        news or information about us and our select partners; and sending you
        technical notices, updates, security alerts, and support and
        administrative messages. Generally, you have the ability to opt out of
        receiving any promotional communications;<br />
        – Investigate and prevent fraudulent transactions, unauthorized access
        to SecurTeam Services, and other illegal activities;<br />
        – Enable you to communicate, collaborate, and share content with users
        you designate; and<br />
        – For other purposes about which we will notify you.
      </p>
      <p>
        Notwithstanding the foregoing, we will not use personal information
        appearing in our analytics logs or web logs for any purpose other than
        our internal maintenance and improvement, and will never share this data
        with any third party.
      </p>
      <p>
        <strong>Compliance with Laws and Law Enforcement Requests</strong>.
        Protection of Our Rights: In rare cases, we may disclose the little
        information we have at our disposal (including your Personal
        Information) to a third party if (a) we believe that disclosure is
        reasonably necessary to comply with any applicable law, regulation,
        legal process or governmental request, (b) to enforce our agreements,
        policies and terms of service, (c) to protect the security or integrity
        of SecurTeam products and services, (d) to protect SecurTeam, our
        customers or the public from harm or illegal activities, or (e) to
        respond to an emergency which we believe in the good faith requires us
        to disclose information to assist in preventing the death or serious
        bodily injury of any person. Note that this does not enable us to read
        or access the content of your communications, which we are not
        technically able to intercept.
      </p>
      <p>
        We may change this privacy policy from time to time. If we make any
        changes, we will notify you by revising the “Effective Starting” date at
        the top of this Privacy Policy and, in some cases, where appropriate we
        may provide you with additional notice (such as adding a statement to
        the login screen or sending you an email notification). We encourage you
        to review our Privacy Policy whenever you use SecurTeam Services to stay
        informed about our information practices and the ways you can help
        protect your privacy.
      </p>
      <p>
        <strong>International users</strong>. If you are visiting from the
        European Union or other regions with laws governing data collection and
        use, please note that should you provide any Personal Information, you
        are agreeing to the potential transfer of this Personal Information to
        Australia and the United States.
      </p>
      <p>
        <strong>Our policy towards children</strong>. SecurTeam Services are not
        directed to individuals under 13. We do not knowingly collect personal
        information from children under 13. If we become aware that a child
        under 13 has provided us with personal information, we will take steps
        to delete such information. If you become aware that a child has
        provided us with Personal Information, please contact our Support Team
        by email support@securteam.com
      </p>
      <p>
        If you disagree with any changes to this Privacy Policy, you will need
        to stop using SecurTeam Services and deactivate your account(s).
      </p>
      <p>&nbsp;</p>
    </div>
    <div class="center gap-50">
      <router-link to="/signup" class="btn">GET IT NOW</router-link>
      <img
        src="https://www.securteam.com/wp-content/uploads/2020/03/securcom-3-logos.fw_.png"
        alt=""
        class="logo w100"
      />
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.logo {
  max-width: 500px;
}
</style>