<template>
  <div class="main_container">
    <Policy />
    <Plan />
  </div>
</template>

<script>
// @ is an alias to /src
import Policy from "@/components/policy/Policy.vue";
import Plan from "@/components/home/Plan.vue";

export default {
  name: "Home",
  components: {
    Policy,
    Plan
  },
};
</script>
